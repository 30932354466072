<template>
  <div class="page-main">
    <div class="home">
      <div class="logo-container">
        <img class="logo" src="../assets/rsc-logo.png" />
      </div>
    </div>
    <!-- <div class="home">
      <img src="../assets/images/gif/1.png" />
    </div> -->
    <div class="home">
      <p class="txt">ESTAMOS ACTUALIZANDO NUESTRO SITIO PARA BRINDARTE UN MEJOR SERVICIO</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import { CardVideo } from '@/components';
export default {
  name: 'HomeView',
  // components: {
  //   CardVideo
  // }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat');

.page-main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: #000;
  background-image: url("@/assets/images/bg/01.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.home {
  width: 100%;
  text-align: center;
  padding: 5px;
}

.logo-container {
  margin: 0 auto;
  width: 200px;
  background-color: #fbf8f894;
}

.logo {
  width: 200px;
}

.txt {
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}</style>
