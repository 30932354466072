<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  top: 0;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #f1e9e9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: justify;
  
}
</style>
